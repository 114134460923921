import { SettingsRow } from '@/common/types/settings.types';
import { Feature } from '@/features.types';

const merchantSettings: SettingsRow[] = [
  {
    columns: [
      {
        title: 'Advanced Settings',
        settings: [
          {
            title: 'Security Keys',
            route: 'settings.security-keys',
            icon: '$key',
            help: `A GPG public key is a cryptographic key used for secure communication and
            digital signatures. It is part of a public-key cryptography system that allows
            messages to be encrypted by the sender using the recipient's public key, and
            then decrypted by the recipient using their private key.`,
          },
          {
            title: 'Credit/Debit Acceptance',
            route: 'merchant.settings.card-acceptance',
            icon: '$window-maximize',
            help: `Transaction limits are a type of fraud prevention measure used in payment
            processing systems to limit the amount of money that can be transacted in a single
            transaction or within a specific time frame.`,
          },
          {
            title: 'Processor Settlement Time',
            route: 'merchant.settings.processors',
            icon: '$clock',
            help: `Processor Settlement Time is the duration it takes for funds to move from a
            customer's bank account to a merchant's bank account after a payment has been
            processed. This setting will allow you to configure batch settlement times for
            your business.`,
          },
          {
            title: 'API Tokens',
            route: 'settings.api-tokens',
            icon: 'mdi-api',
            help: `API tokens are unique identifiers used to authenticate and authorize access
            to an API. They are typically issued to users or applications and are used to ensure
            secure and controlled access to API resources.`,
          },
          {
            title: 'Webhooks',
            route: 'settings.webhooks',
            icon: '$satellite-dish',
          },
        ],
      },
      {
        title: 'General Settings',
        settings: [
          {
            title: 'Users',
            route: 'merchant.users.index',
            icon: '$users',
          },
          {
            title: 'Custom Fields',
            route: 'merchant.settings.custom-fields',
            icon: '$window-maximize',
          },
          {
            title: 'Cash Discount',
            route: 'merchant.settings./cash-discount',
            icon: 'mdi-credit-card-outline',
            feature: Feature.NON_CASH_ADJUSTMENT,
          },
          {
            title: 'Convenience Fee',
            route: 'merchant.settings./convenience-fee',
            icon: 'mdi-credit-card-outline',
            feature: Feature.CONVENIENCE_FEE,
          },
          {
            title: 'Customer Information Fields',
            route: 'merchant.settings.info-fields',
            icon: '$window-maximize',
            feature: Feature.INFO_FIELDS,
            help: `Customer Information Fields allow you to choose what information you would like
            to display on your Virtual Terminal, or Hosted Payments Pages. Each field can be
            enabled/disabled or set to required.`,
          },
          {
            title: 'Email Settings',
            route: 'merchant.settings.email-settings',
            icon: 'mdi-receipt',
          },
          {
            title: 'Level III settings',
            route: 'merchant.settings.level-3-settings',
            icon: '$window-maximize',
            feature: Feature.LEVEL23,
            help: `Customer Information Fields allow you to choose what information you would like
            to display on your Virtual Terminal, or Hosted Payments Pages. Each field can be
            enabled/disabled or set to required.`,
          },
          {
            title: 'Receipt Settings',
            route: 'merchant.settings.receipt-settings',
            icon: 'mdi-receipt',
          },
          {
            title: 'Invoice Settings',
            route: 'merchant.settings.invoice-settings',
            icon: 'mdi-receipt',
          },
        ],
      },
      {
        title: 'Fraud Prevention Settings',
        settings: [
          {
            title: 'AVS Settings',
            route: 'merchant.settings.avs-settings',
            icon: 'mdi-credit-card-outline',
            help: `AVS (Address Verification System) settings verify the billing address provided
            by a customer during a transaction. AVS settings compare the billing address entered
            by the customer with the address on file with the card-issuing bank to help prevent
            fraudulent transactions.`,
          },
          {
            title: 'IP Whitelist',
            route: 'merchant.settings.ip-settings',
            icon: 'mdi-credit-card-outline',
            help: `IP whitelist is a security feature that allows access only to approved IP
            addresses.`,
          },
          {
            title: 'Transaction IP Blocker',
            route: 'merchant.settings.ip-blocker',
            icon: '$window-maximize',
            help: `Transaction IP Blocker is a security feature used in payment processing
            systems to block transactions originating from specific IP addresses. This is a
            measure to prevent fraudulent activity and can be set up to block transactions from
            known sources of fraud or suspicious activity.`,
          },
          {
            title: 'Transaction Limits',
            route: 'merchant.settings.transaction-limits',
            icon: '$window-maximize',
            help: `Transaction limits are a type of fraud prevention measure used in payment
            processing systems to limit the amount of money that can be transacted in a single
            transaction or within a specific time frame.`,
          },
          {
            title: 'Rate Limits',
            route: 'merchant.settings.rate-limiter',
            icon: '$window-maximize',
          },
        ],
      },
    ],
  },
];

export default merchantSettings;
