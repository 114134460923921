import * as Sentry from '@sentry/browser';
import Vue from 'vue';
import Vuex from 'vuex';

import { setFullyAuthenticated } from '@/common/api';
import actions from './actions';
import {
  get as currentUser,
  displayName as currentUserDisplayName,
  can as currentUserCan,
} from './getters/currentUser';
import { MerchantFeatureName } from '../types/features.types';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    context: null,

    hasCompletedAuthFlow: false,
    accessToken: null,
    currentUser: null,

    redirect: '',

    branding: null,
    brandName: '',
    logo: '',
    brandCompany: '',

    processors: [],

    features: [],

    availableProcessors: [],

    virtualTerminal: null,
  },
  mutations: {
    completeAuthFlow(state) {
      state.hasCompletedAuthFlow = true;
    },
    clearAccessToken(state) {
      state.accessToken = null;
    },
    setAccessToken(state, token) {
      state.accessToken = token;
    },
    setContext(state, context) {
      state.context = context;
    },
    setCurrentUser(state, user) {
      state.currentUser = user;
      setFullyAuthenticated();
      Sentry.configureScope((scope) => {
        scope.setUser({ id: user.id });
      });
    },
    setBranding(state, branding) {
      state.branding = branding;
    },
    setBrandName(state, name) {
      state.brandName = name;
    },
    setBrandCompany(state, company) {
      state.brandCompany = company;
    },
    setLogo(state, logo) {
      state.logo = logo;
    },
    setRedirect(state, path) {
      if (!state.redirect) {
        state.redirect = path;
      }
    },
    setProcessors(state, processors) {
      state.processors = processors;
    },
    setFeatures(state, features) {
      state.features = features;
    },
    setAvailableProcessors(state, availableProcessors) {
      state.availableProcessors = availableProcessors;
    },
    setVirtualTerminal(state, virtualTerminal) {
      state.virtualTerminal = virtualTerminal;
    },
  },
  actions,
  getters: {
    accessToken: ({ accessToken }) => accessToken,
    authenticated: ({ accessToken }) => accessToken !== null,
    branding: ({ branding }) => branding,
    brandName: ({ brandName }) => brandName,
    brandCompany: ({ brandCompany }) => brandCompany,
    isIsoContext: ({ context }) => context === 'iso',
    isMerchantContext: ({ context }) => context === 'merchant',
    isAdminContext: ({ context }) => context === 'admin',
    currentUser,
    currentUserCan,
    currentUserDisplayName,
    currentUserIsInRoleContext: ({ context, currentUser: user }) => (
      context && user && context === user.role
    ),
    redirect: ({ redirect }) => redirect,
    context: ({ context }) => context,
    processors: ({ processors }) => processors,
    features: ({ features }) => features,
    availableProcessors: ({ availableProcessors }) => availableProcessors,
    virtualTerminal: ({ virtualTerminal }) => virtualTerminal,
    isCustomerVaultEnabled: ({ features }) => {
      const feature = features
        .find((f) => f.name === MerchantFeatureName.CUSTOMER_VAULT);
      return feature ? feature.enabled : false;
    },
    // TODO Invoicing Add
    isInvoicingEnabled: ({ features }) => {
      const feature = features
        .find((f) => f.name === MerchantFeatureName.INVOICING);
      return feature ? feature.enabled : false;
    },
    isSurchargingEnabled: ({ features }) => {
      const feature = features
        .find((f) => f.name === MerchantFeatureName.SURCHARGING);
      return feature ? feature.enabled : false;
    },
    isCustomerElectronicCheckEnabled: ({ features }) => {
      const feature = features
        .find((f) => f.name === MerchantFeatureName.ELECTRONIC_CHECK);
      return feature ? feature.enabled : false;
    },
    isCustomerKeybankElectronicCheckEnabled: ({ features }) => {
      const feature = features
        .find((f) => f.name === MerchantFeatureName.ELECTRONIC_CHECK_KEYBANK);
      return feature ? feature.enabled : false;
    },
    isCustomerCashEnabled: ({ features }) => {
      const feature = features
        .find((f) => f.name === MerchantFeatureName.CASH);
      return feature ? feature.enabled : false;
    },
    isEnhancedDataEnabled: ({ features }) => {
      const feature = features
        .find((f) => f.name === MerchantFeatureName.ENHANCED_DATA_LEVEL_3);
      return feature ? feature.enabled : false;
    },
    isShoppingCartEnabled: ({ features }) => {
      const feature = features
        .find((f) => f.name === MerchantFeatureName.SHOPPING_CART);
      return feature ? feature.enabled : false;
    },
    isNonCashAdjustmentEnabled: ({ features }) => {
      const feature = features
        .find((f) => f.name === MerchantFeatureName.NON_CASH_ADJUSTMENT);
      return feature ? feature.enabled : false;
    },
    isConvenienceFeeEnabled: ({ features }) => {
      const feature = features
        .find((f) => f.name === MerchantFeatureName.CONVENIENCE_FEE);
      return feature ? feature.enabled : false;
    },
    logo: ({ logo }) => logo,
  },
});
