import { MerchantFeature, MerchantFeatureName } from '../types/features.types';

// eslint-disable-next-line import/prefer-default-export
export function initMerchantFeastures(): MerchantFeature[] {
  return [
    {
      name: MerchantFeatureName.CUSTOMER_VAULT,
      enabled: false,
    },
    {
      name: MerchantFeatureName.ENHANCED_DATA_LEVEL_3,
      enabled: false,
    },
    {
      name: MerchantFeatureName.AUTOMATIC_CARD_UPDATER,
      enabled: false,
    },
    {
      name: MerchantFeatureName.INVOICING,
      enabled: false,
    },
    {
      name: MerchantFeatureName.SURCHARGING,
      enabled: false,
    },
    {
      name: MerchantFeatureName.ELECTRONIC_CHECK,
      enabled: false,
    },
    {
      name: MerchantFeatureName.ELECTRONIC_CHECK_KEYBANK,
      enabled: false,
    },
    {
      name: MerchantFeatureName.CASH,
      enabled: false,
    },
    {
      name: MerchantFeatureName.SHOPPING_CART,
      enabled: false,
    },
    {
      name: MerchantFeatureName.NON_CASH_ADJUSTMENT,
      enabled: false,
    },
    {
      name: MerchantFeatureName.CONVENIENCE_FEE,
      enabled: false,
    },
  ];
}
