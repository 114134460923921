export enum MerchantFeatureName {
  CUSTOMER_VAULT = 'customer_vault',
  ENHANCED_DATA_LEVEL_3 = 'enhanced_data',
  AUTOMATIC_CARD_UPDATER = 'automatic_card_updater',
  INVOICING = 'invoicing',
  SURCHARGING = 'surcharging',
  ELECTRONIC_CHECK = 'electronic_check',
  ELECTRONIC_CHECK_KEYBANK = 'electronic_check_keybank',
  CASH = 'cash',
  SHOPPING_CART = 'shopping_cart',
  NON_CASH_ADJUSTMENT = 'cash_discount',
  CONVENIENCE_FEE = 'convenience_fee',
}

export interface MerchantFeature {
  name: MerchantFeatureName;
  enabled: boolean;
}

export interface MerchantFeatureOption {
  value: MerchantFeatureName|string;
  display: string;
}
